<template>
	<div class="fullPage">
		<headers :current="0" :is-tm="true" />
		<div class="search">
			<div class="logo">
				<img src="../../assets/img/indexLogo.png" alt="">
			</div>
			<div class="topList flexleft">
				<div v-for="(item, index) in topList" :key="index" class="child"
					:class="topCurrent == index ? 'active' : ''" @click="changeTop(index)">
					{{ item }}
				</div>
			</div>
			<div class="topSec flexleft">
				<div class="searchImg">
					<img src="../../assets/image/search.png" alt="">
				</div>
				<div class="ipt flexleft">
					<el-select slot="prepend" v-model="type" class="sel" placeholder="请选择" v-if="topCurrent == 5">
						<el-option label="供应" :value="1" />
						<el-option label="需求" :value="2" />
					</el-select>
					<input type="text" style="width: 100%;" placeholder="请输入搜索内容" @keyup.enter="toSearch"
						v-model="keywords" />
				</div>
				<div class="btn cur" @click="toSearch">搜索</div>
			</div>
		</div>
		<div class="numList">
			<div class="childList flexbetween">
				<div class="numChild flexleft">
					<div class="imgs">
						<img src="../../assets/img/dataScreen.png" alt="">
					</div>
					<div class="rightSec">
						<div class="data">数据大屏</div>
						<div class="btn">
							<el-button round class="goBtn" @click="toDataScreen">进入→</el-button>
						</div>
					</div>
				</div>
				<div class="numChild flexleft">
					<div class="img">
						<img src="../../assets/img/yrz.png" alt="">
					</div>
					<div class="rightSec">
						<div class="num">
							<animated-number :value="data.enterprise_num" :duration="1000"
								:format-value="formatToPrice" />
						</div>
						<div class="txt">已入驻企业数</div>
					</div>
				</div>
				<div class="numChild flexleft">
					<div class="img">
						<img src="../../assets/img/yhz.png" alt="">
					</div>
					<div class="rightSec">
						<div class="num">
							<animated-number :value="data.yhenterprise_num" :duration="1000"
								:format-value="formatToPrice" />
						</div>
						<div class="txt">已获知产企业</div>
					</div>
				</div>
				<div class="numChild flexleft">
					<div class="img">
						<img src="../../assets/img/zj.png" alt="">
					</div>
					<div class="rightSec">
						<div class="num">
							<animated-number :value="data.expert_manage_num" :duration="1000"
								:format-value="formatToPrice" />
						</div>
						<div class="txt">专家数量</div>
					</div>
				</div>
				<div class="numChild flexleft">
					<div class="img">
						<img src="../../assets/img/rc.png" alt="">
					</div>
					<div class="rightSec">
						<div class="num">
							<animated-number :value="data.talent_manage_num" :duration="1000"
								:format-value="formatToPrice" />
						</div>
						<div class="txt">人才数量</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tjsb section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">最新动态</div>
				</div>
				<div class="rightTitle flexleft" @click="jump('dynamicList')">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/img/yjt.png" alt="">
					</div>
				</div>
			</div>
			<div class="tjsbCenter flexleft">
				<div class="swiperSec">
					<el-carousel ref="carousel" indicator-position="none" arrow="never" height="23rem" @change="change">
						<el-carousel-item v-for="(item, index) in data.trends" :key="index">
							<img :src="$utils.addurl(item.image)" alt="">
						</el-carousel-item>
					</el-carousel>
					<div class="botTag flexbetween">
						<div class="txts" v-for="(items, indexs) in data.trends" :key="indexs"
							v-show="indexs == current">
							{{items.title}}
						</div>
						<div class="littles flexleft">
							<div v-for="(items, indexs) in data.trends.length" :key="indexs" class="little"
								:class="current == indexs ? 'active' : ''" @click="changeTag(indexs)" />
						</div>
					</div>
				</div>
				<div class="tjsbList">
					<div v-for="(item, index) in data.trends" :key="index" class="child flexleft"
						@click="toDetail(item.id, 'activityDetail')">
						<div class="leftImg">
							<img :src="$utils.addurl(item.image)" alt="">
						</div>
						<div class="txts">
							<div class="titles">
								{{ item.title }}
							</div>
							<div class="date">
								{{ $utils.getDate(item.createtime) }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="policyData section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">政策资源</div>
				</div>
				<div class="rightTitle flexleft" @click="jump('policyData')">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/img/yjt.png" alt="">
					</div>
				</div>
			</div>
			<div class="center flexleft">
				<div class="leftSec">
					<div class="leftTitle flexbetween">
						<div class="txt">政策知识库</div>
						<div class="more cur" @click="jump('knowledgeList')">更多>></div>
					</div>
					<div class="list">
						<div v-for="(item, index) in data.knowledge" :key="index" class="child flexleft"
							@click="toDetail(item.id, 'knowledgeDetail')">
							<div class="little" />
							<div class="txts">
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
				<div class="rightSec" v-loading="loading">
					<div class="rightTitle">最新政策</div>
					<div class="tabList flexleft">
						<div :class="policyCurrent == index?'tabChild cur active':'tabChild cur'"
							v-for="(item,index) in policyTabList" :key="index" @click="changePolicyTab(item,index)">
							{{item.name}}</div>
					</div>
					<div class="list" element-loading-text="数据加载中">
						<div v-for="(item, index) in policyList" :key="index" class="topData">
							<div class="topTitle">
								{{ item.createtime }} 丨 {{ item.entry_name }}（{{ item.level }}）
							</div>
							<div class="table">
								<el-table :data="item.comapny" style="width: 100%"
									:height="item.comapny.length>5?'160':'auto'">
									<el-table-column label="企业名称" show-overflow-tooltip>
										<template slot-scope="scope">
											<span class="cur" @click="toCompanyDetail(scope.row.id)">{{
												scope.row.enterprise_name || "--"
											  }}</span>
										</template>
									</el-table-column>
									<el-table-column label="课题名称" width="240" show-overflow-tooltip>
										<template slot-scope="scope">
											{{ scope.row.topic_name || "--" }}
										</template>
									</el-table-column>
									<el-table-column prop="level" label="等级" width="120" />
									<el-table-column prop="unit" label="部门" width="120" />
									<el-table-column prop="total_money" label="补贴金额" width="120">
										<template slot-scope="scope">
											{{ scope.row.total_money? `${scope.row.total_money.toFixed(2)}万`:0 }}
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="policy section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">政策资讯</div>
				</div>
				<div class="rightTitle flexleft" @click="toZczx">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/img/yjt.png" alt="">
					</div>
				</div>
			</div>
			<div class="center" v-loading="loadingPolicy">
				<div class="centerTitle flexleft">
					<div v-for="(item, index) in tabList" :key="index" class="childTitle"
						:class="tabCurrent == index ? 'col' : ''" @click="changeTab(index)">
						{{ item }}
					</div>
				</div>
				<div class="centerList flexbetween">
					<div v-for="(item, index) in sbList" :key="index" class="child cur flexleft"
						@click="toDetail(item.id, 'policyDetail', tabCurrent)">
						<div class="littleD" />
						<div class="txt">
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="dataScreen section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/page4/d.png" alt="" />
					</div>
					<div class="leftTxt">数据大屏</div>
				</div>
				<div class="rightTitle flexleft">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/page4/d.png" alt="" />
					</div>
				</div>
			</div>
			<div class="screenData">
				<el-carousel :interval="4000" :autoplay="false" type="card" height="26rem" indicator-position="none" arrow="never">
					<el-carousel-item v-for="item in 4" :key="item">
						<img src="../../assets/page4/bg1.png" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>
		</div> -->

		<!-- <div class="activity section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/page4/d.png" alt="" />
					</div>
					<div class="leftTxt">活动信息</div>
				</div>
				<div class="rightTitle flexleft">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/page4/d.png" alt="" />
					</div>
				</div>
			</div>
			<div class="center flexleft">
				<div class="leftSec">
					<div class="lftTitle">活动日历 <span>本月共5场活动</span></div>
					<el-calendar v-model="value"></el-calendar>
				</div>
				<div class="rightSec flexleft">
					<div class="child" v-for="(item,index) in 3" :key="index">
						<div class="topC">活动简介</div>
						<div class="titleC">标题标题标题标题标标题标题标题标题标标题标题标题标题标</div>
						<div class="botC">
							<p>主办:工信部</p>
							<p>时间:2023-12-27</p>
						</div>
						<div class="img">
							<img src="../../assets/page4/bg2.png" alt="" />
						</div>
					</div>
				</div>
			</div>
			
		</div> -->
		<div class="service section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">惠企服务</div>
				</div>
				<div class="rightTitle flexleft" @click="jump('serve')">
					<div class="rightTxt">查看更多</div>
					<div class="rightImg">
						<img src="../../assets/img/yjt.png" alt="">
					</div>
				</div>
			</div>
			<div class="list flex">
				<div v-for="(item, index) in hqfwList" :key="index" class="child cur"
					@click="toDetail(item.id, 'report')">
					<div class="topImg">
						<img :src="$utils.addurl(item.image)" alt="">
					</div>
					<div class="botTxt">
						{{ item.policy_name }}
					</div>
				</div>
			</div>
		</div>

		<div class="enterprise section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">企业诉求</div>
				</div>
			</div>
			<div class="center flexbetween">
				<div class="leftSec">
					<div class="steps flexleft">
						<div class="child flexleft">
							<div class="childStep">
								<div class="img">
									<img src="../../assets/img/subsq.png" alt="">
								</div>
								<div class="txt">提交诉求</div>
							</div>
							<div class="arrowhead">
								<img src="../../assets/img/nextjt.png" alt="">
							</div>
						</div>
						<div class="child flexleft">
							<div class="childStep">
								<div class="img">
									<img src="../../assets/img/hssq.png" alt="">
								</div>
								<div class="txt">核实诉求</div>
							</div>
							<div class="arrowhead">
								<img src="../../assets/img/nextjt.png" alt="">
							</div>
						</div>
						<div class="child flexleft">
							<div class="childStep">
								<div class="img">
									<img src="../../assets/img/lssq.png" alt="">
								</div>
								<div class="txt">落实诉求</div>
							</div>
							<div class="arrowhead">
								<img src="../../assets/img/nextjt.png" alt="">
							</div>
						</div>
						<div class="child flexleft">
							<div class="childStep">
								<div class="img">
									<img src="../../assets/img/gzsq.png" alt="">
								</div>
								<div class="txt">跟踪诉求</div>
							</div>
							<div class="arrowhead">
								<img src="../../assets/img/nextjt.png" alt="">
							</div>
						</div>
						<div class="child flexleft">
							<div class="childStep">
								<div class="img">
									<img src="../../assets/img/oversq.png" alt="">
								</div>
								<div class="txt">诉求结束</div>
							</div>
						</div>
					</div>
					<div class="btn cur" @click="btnsq">即刻提交</div>
				</div>
				<div class="rightSec flexleft">
					<div class="child">
						<div class="topImg">
							<img src="../../assets/img/zczxicon.png" alt="">
						</div>
						<div class="txt">政策资讯</div>
						<div class="num">
							{{ data.policy_count }}
						</div>
					</div>
					<div class="child">
						<div class="topImg">
							<img src="../../assets/img/fwxqicon.png" alt="">
						</div>
						<div class="txt">服务需求</div>
						<div class="num">
							{{ data.service_count }}
						</div>
					</div>
					<div class="child">
						<div class="topImg">
							<img src="../../assets/img/wtjyicon.png" alt="">
						</div>
						<div class="txt">问题建议</div>
						<div class="num">
							{{ data.problem_count }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="expert section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">专家与人才</div>
				</div>
			</div>
			<div class="center flexbetween">
				<!-- @mousedown="clickDown" @mouseup="clickUp" -->
				<!-- @mouseenter="clearItv" @mouseleave="continueXh" -->
				<div class="leftSec">
					<div class="leftTitle flexbetween">
						<div class="titleTxt">找专家</div>
						<div class="titleMore" @click="jump('expertList')">更多>></div>
					</div>
					<div class="tabs">
						<!-- :style="{transform:'translateX(-' + expertTransformX + 'px)'}" -->
						<div class="nList flexleft">
							<div v-for="(item, index) in expertList" :key="index" class="tabChild cur"
								:class="expertCurrent == index ? 'active' : ''" @click="changeZjTab(index)">
								{{ item.institutions_name }}
								<div v-show="expertCurrent == index" class="little">
									<img src="../../assets/img/littlesj.png" style="object-fit: cover;" alt="">
								</div>
							</div>
						</div>
					</div>
					<div class="personList flexleft">
						<div v-for="(item, index) in zjList" :key="index" class="personChild cur"
							@click="toDetail(item.id, 'expertDetail')">
							<div class="personImg">
								<!-- <img :src="$utils.addurl(item.image)" alt=""> -->
								<img :src="$utils.addurl('/static/tutorAvatar.png')" alt="">
							</div>
							<div class="personBot">
								<div class="name">
									{{ item.name || "--" }}
								</div>
								<div class="little">···</div>
								<div class="skill">
									擅长领域 {{ item.case ? item.case : "暂无" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="rightSec">
					<div class="leftTitle flexbetween">
						<div class="titleTxt">觅人才</div>
						<div class="titleMore" @click="jump('personlist')">更多>></div>
					</div>
					<div class="talent flexbetween">
						<div v-for="(item, index) in rcList" :key="index" class="child flexleft cur"
							@click="toDetail(item.id, 'persondetail')">
							<div class="leftSecs">
								<div class="name">
									{{ item.name }}
								</div>
								<div class="info">
									{{ item.gender == 1 ? "男" : item.gender == 2 ? "女" : "未知"
                  }}<span>|</span>{{ item.age }}岁<span>|</span>{{ item.education }}
								</div>
								<div class="job">
									<span class="hTxt">期望岗位：</span>
									<span class="cTxt">{{ item.post }}</span>
								</div>
								<div class="job">
									<span class="hTxt">工作地点：</span>
									<span class="cTxt">{{ item.desirearea }}</span>
								</div>
							</div>
							<div class="rightSecs">
								<img :src="$utils.addurl(item.image)" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="serviceSystem section">
			<div class="title flexbetween">
				<div class="leftTitle flexleft">
					<div class="leftImg">
						<img src="../../assets/img/sifang.png" alt="">
					</div>
					<div class="leftTxt">服务体系</div>
				</div>
			</div>
			<div class="list">
				<!-- :style="{
						transform: 'translateX(-' + serviceSystemTransformX + 'px)',
						transition: serviceSystemTransition,
					  }" -->
				<div class="nLists flexleft" :style="{ transform: `translateX(${offset}px)` }">
					<div v-for="(item, index) in extendedItems" :key="index" class="child flexleft firstChild cur" @click="open(item.url)">
						<div class="logo">
							<img :src="$utils.addurl(item.image)" alt="">
						</div>
						<div class="txt">
							<span>{{ item.title }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fix">
			<el-tooltip content="立即咨询" placement="left" effect="dark" popper-class="atooltip">
				<div class="child" @click="btnzx">
					<img src="../../assets/img/sideone.png" alt="">
				</div>
			</el-tooltip>
			<div class="child">
				<img src="../../assets/img/sidetwo.png" alt="">
				<div class="list">
					<div class="xsj">
						<img src="../../assets/img/indexxsj.png" class="xxsj" alt="">
					</div>
					<div v-for="(item, index) in fixList" :key="index" class="lChild" @click="scrollTo(item.num)">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="child" @click="toTop">
				<img src="../../assets/img/sidethree.png" alt="">
			</div>
		</div>
		<footers />
		<qrcodes :show="show" />
	</div>
</template>

<script>
	import AnimatedNumber from "animated-number-vue";
	export default {
		components: {
			AnimatedNumber,
		},
		data() {
			return {
				offset: 0,
				itemWidth: 210, // 每个项的宽度
				scrollSpeed: 0.5, // 滚动速度
				fixList: [{
						name: "最新动态",
						num: 700,
					},
					{
						name: "政策资讯",
						num: 1600,
					},
					// {
					// 	name: "数据大屏",
					// 	num: 0,
					// },
					{
						name: "政策资源",
						num: 1150,
					},
					{
						name: "惠企服务",
						num: 2100,
					},
					{
						name: "企业诉求",
						num: 2400,
					},
					{
						name: "专家人才",
						num: 2700,
					},
					{
						name: "服务体系",
						num: 2700,
					},
				],
				loadingPolicy: false,
				fwList: [],
				zjList: [],
				zjTzbList: [],
				rcList: [],
				type: 1,
				hqfwList: [],
				sbList: [],
				policyList: [],
				data: "",
				show: false,
				bigList: [],
				companyList: [],
				expertList: [
					"成功转化",
					"财税服务",
					"质量标准品牌",
					"人力资源人力资源",
					"人力资源",
					"质量标准品牌",
				],
				expertCurrent: 0, //找专家tab
				expertTotal: 0,
				expertTransformX: 0,
				topList: ["企业", "政策", "专家", "服务", "供需"],
				topCurrent: 0,
				tabList: ["正在申报", "正在公示", "其它资讯"],
				current: 0,
				tabCurrent: 0,
				value: new Date(),
				expertTimer: "", //找专家定时器
				serviceSystemTimer: "", //服务体系定时器
				serviceSystemTransformX: 0,
				serviceSystemTransition: "all 1s linear",
				serviceSystemNum: 1,
				configData: {
					lbt: [],
				},
				keywords: "",
				clickX: 0,
				loading: false,
				policyTabList: [{
					name: '国家级',
					id: 18,
				}, {
					name: '省级',
					id: 19,
				}, {
					name: '市级',
					id: 20,
				}, {
					name: '区县级',
					id: 21,
				}, ],
				policyCurrent: 0,
				policyTabId: 18,
				fwListCopy:[],
				timer:'',
			};
		},
		watch: {
			expertCurrent() {
				this.getZjData();
			},
		},
		computed: {
			extendedItems() {
				if (this.fwList.length < 5) {
					return this.fwList; // 将原始数组拼接一份作为扩展数组
				} else {
					return this.fwList.concat(this.fwList); // 将原始数组拼接一份作为扩展数组
				}
			}
		},
		created() {
			this.expertTimer = setInterval(() => {
				if (this.expertCurrent < this.expertTotal - 1) {
					this.expertCurrent++;
				} else {
					this.expertCurrent = 0;
					this.expertTransformX = 0;
				}
				let elements = document.querySelectorAll(".tabChild");
				let targetElement = elements[this.expertCurrent];
				let targetElements =
					this.expertCurrent < this.expertList.length - 1 ?
					elements[this.expertCurrent + 1] :
					null;

				if (targetElement.offsetLeft >= 500 && targetElements) {
					this.expertTransformX += targetElements.offsetWidth + 30;
				}

				let nList = document.querySelector(".nList");
				let margin = 0.6; // 间距为0.6rem

				let selectedElement = elements[this.expertCurrent];
				let selectedElementWidth =
					selectedElement.offsetWidth +
					margin * parseFloat(getComputedStyle(selectedElement).fontSize) * 2;
				let containerWidth = nList.offsetWidth;
				let centerOffset = (containerWidth - selectedElementWidth) / 2;
				let scrollDistance = 0;
				for (let i = 0; i < this.expertCurrent; i++) {
					let elementWidth =
						elements[i].offsetWidth +
						margin * parseFloat(getComputedStyle(elements[i]).fontSize) * 2;
					scrollDistance += elementWidth;
				}
				scrollDistance -= centerOffset;
				nList.scrollTo({
					left: scrollDistance,
					behavior: "smooth",
				});
			}, 5000);
		},
		destroyed() {
			clearInterval(this.expertTimer);
			clearInterval(this.serviceSystemTimer);
		},
		mounted() {
			let _this = this;
			_this.getIdxData(); //首页数据
			_this.getNewPolicyData(); //最新政策
			_this.getNowSb(); //正在申报
			_this.getRcList(); //人才列表
			_this.getHqfw(); //惠企服务
			_this.getZjyx(); //专家所属院校
			_this.getFwtx(); //服务体系
			window.addEventListener("scroll", this.handleScroll, true);
			_this.getZjData();
			_this.getConfig();
		},
		methods: {
			changePolicyTab(item, index) {
				this.policyCurrent = index;
				this.policyTabId = item.id;
				this.getNewPolicyData(); //最新政策
			},
			startScroll() {
				this.timer = setInterval(() => {
					this.offset -= this.scrollSpeed;
					if (this.offset <= -this.fwList.length * this.itemWidth) {
						setTimeout(() => {
							this.offset = 0;
						},440)
						// console.log(115555)
						// this.fwList.concat(this.fwListCopy); // 将原始数组拼接一份作为扩展数组
					}
				}, 11); // 控制滚动速度的间隔时间
			},
			btnsq() {
				if (!localStorage.getItem("userId")) {
					this.$message({
						message: '请先登录',
						offset: 150,
						type: 'warning'
					});
					return;
				}
				if (!localStorage.getItem("qyId")) {
					return this.$message.error("请到个人中心补全企业信息");
				}
				this.jump("subEnterprise");
			},
			btnzx() {
				let _this = this;
				if (!localStorage.getItem("userId")) {
					this.$message({
						message: '请先登录',
						offset: 150,
						type: 'warning'
					});
					// _this.$bus.$emit('change', true);
					return;
				}
				_this.$router.push("companyConsultation");
			},
			clickDown(e) {
				this.clickX = e.clientX;
			},
			clickUp(e) {
				let addPx = e.clientX - this.clickX;
				this.expertTransformX += addPx;
			},
			toSearch() {
				if (!localStorage.getItem("userId")) {
					this.$message({
						message: '请先登录',
						offset: 150,
						type: 'warning'
					});
					return;
				}
				let _this = this;
				if (_this.topCurrent == 0) {
					// _this.$router.push({
					// 	path: "policyList",
					// 	query:{
					// 		keyword:_this.keywords
					// 	}
					// });
					_this.$router.push({
						path: "enterpriseLibrary",
						query: {
							keyword: _this.keywords
						}
					});
				} else if (_this.topCurrent == 1) {
					_this.$router.push({
						path: "policyData",
						query: {
							keyword: _this.keywords
						}
					});
				} else if (_this.topCurrent == 2) {
					_this.$router.push({
						path: "expertList",
						query: {
							keyword: _this.keywords
						}
					});
				} else if (_this.topCurrent == 3) {
					_this.$router.push({
						path: "serviceList",
						query: {
							keyword: _this.keywords
						}
					});
				} else if (_this.topCurrent == 4) {
					if (_this.type == 1) {
						_this.$router.push({
							path: "productList",
							query: {
								keyword: _this.keywords
							}
						});
					} else {
						_this.$router.push({
							path: "demandList",
							query: {
								keyword: _this.keywords
							}
						});
					}
				}
			},
			scrollTo(num) {
				window.scrollTo({
					top: num,
					behavior: "smooth",
				});
			},
			toCompanyDetail(id) {
				this.$router.push({
					path: "company",
					query: {
						id,
					},
				});
			},
			getConfig() {
				let _this = this;
				_this.$api.yms.pcconfig().then((e) => {
					_this.configData = e.data;
					_this.configData.lbt = _this.configData.lbt.split(",");
				});
			},
			getFwtx() {
				let _this = this;
				_this.$api.yms
					.fwystem({
						page: 1,
						pageSize: 999,
					})
					.then((e) => {
						_this.fwList = e.data.list;
						_this.fwListCopy = e.data.list;
						if (e.data.list.length < 5) return
						_this.startScroll();
					});
			},
			clearItv() {
				clearInterval(this.expertTimer);
			},
			continueXh() {
				this.expertTimer = setInterval(() => {
					if (this.expertCurrent < this.expertTotal - 1) {
						this.expertCurrent++;
					} else {
						this.expertCurrent = 0;
						this.expertTransformX = 0;
					}
					let elements = document.querySelectorAll(".tabChild");
					let targetElement = elements[this.expertCurrent];
					if (this.expertCurrent < this.expertList.length - 1) {
						var targetElements = elements[this.expertCurrent + 1];
					}
					if (targetElement.offsetLeft >= 500) {
						if (this.expertCurrent < this.expertList.length - 1) {
							this.expertTransformX += targetElements.offsetWidth + 30;
						}
					}
				}, 2000);
			},
			changeZjTab(index) {
				this.expertCurrent = index;
			},
			getZjData() {
				let _this = this;
				_this.$api.yms.expert_manage_list({
						page: 1,
						pageSize: 4,
						institutions_name: _this.expertList[_this.expertCurrent].institutions_name,
						label1: "",
						area: "",
					})
					.then((e) => {
						_this.zjList = e.data.list;
					});
			},
			getZjyx() {
				let _this = this;
				_this.$api.yms.expert_manage_first().then((e) => {
					_this.expertList = e.data;
					_this.expertTotal = e.data.length;
				});
			},
			toDetail(id, url, status) {
				this.$router.push({
					path: "/" + url,
					query: {
						id,
						status,
					},
				});
			},
			getRcList() {
				let _this = this;
				_this.$api.yms
					.talent_manage_list({
						talent_type_id: "",
						page: 1,
						pageSize: 4,
					})
					.then((e) => {
						_this.rcList = e.data.list;
						let year = new Date().getFullYear();
						_this.rcList.forEach((item, index) => {
							let ages = year - item.birth.split("-")[0];
							_this.$set(item, "age", ages);
						});
					});
			},
			getHqfw() {
				let _this = this;
				_this.$api.yms
					.service_manage({
						page: 1,
						pageSize: 10,
						service_type_id: "",
					})
					.then((e) => {
						_this.hqfwList = e.data.list;
					});
			},
			getOtherSb(index) {
				let _this = this;
				_this.$api.yms
					.bulletin_list({
						page: 1,
						pageSize: 10,
						level: -1,
						type: index == 1 ? 1 : 5,
					})
					.then((e) => {
						_this.sbList = e.data.list;
						_this.loadingPolicy = false;
					});
			},
			getNowSb() {
				let _this = this;
				_this.$api.yms
					.get_information({
						page: 1,
						pageSize: 10,
						type: -1,
					})
					.then((e) => {
						_this.sbList = e.data.list;
						_this.loadingPolicy = false;
					});
			},
			getNewPolicyData() {
				let _this = this;
				_this.loading = true;
				_this.$api.yms.get_policynew({
					level: _this.policyTabId,
				}).then((e) => {
					_this.policyList = e.data;
					_this.loading = false;
				});
			},
			jump(url) {
				this.$router.push({
					path: "/" + url,
				});
			},
			getIdxData() {
				let _this = this;
				_this.$api.yms.index().then((e) => {
					_this.data = e.data;
					_this.data.knowledge = e.data.knowledge.slice(0, 6);
				});
			},
			formatToPrice(value) {
				//使用tofixed（0）限制跳动的小数位数
				return `<h3>${Number(value).toFixed(0)}</h1>`;
			},
			//实时监控元素与窗口滚动的距离
			handleScroll() {
				let top =
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					window.pageYOffset;
				this.scrollTopNum = top;
			},
			// 返回顶部处理函数（滑动滚动）
			toTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			changeTop(index) {
				this.topCurrent = index;
			},
			change(e) {
				this.current = e;
			},
			toZczx() {
				this.$router.push({
					path: "/policyList",
					query: {
						status: this.tabCurrent,
					},
				});
			},
			changeTab(index) {
				this.tabCurrent = index;
				this.loadingPolicy = true;
				if (index == 0) {
					this.getNowSb();
				} else {
					this.getOtherSb(index);
				}
			},
			changeTag(index) {
				this.$refs.carousel.setActiveItem(index);
			},
			toDataScreen() {
				const path = "/dataPage/page1";
				window.open(this.$router.resolve(path).href, "_blank");
			},
			open(url){
				if(url){
					window.open(url, "_blank");
				}
			},
		},
	};
</script>

<style>
	.atooltip {
		background: #000 !important;
		color: #d6b098 !important;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
	}
</style>


<style scoped lang="scss">
	::v-deep .el-input__inner {
		border: 1px solid transparent;
	}

	::v-deep .el-input-group__append {
		border: none !important;
	}

	::v-deep .el-input__inner {
		width: 6rem;
		background-color: #fff !important;
	}

	::v-deep .el-input-group__prepend {
		border: none !important;
	}

	::v-deep .el-input__inner {
		border: 1px solid #fff !important;
	}

	::v-deep .el-input {
		border: none !important;
	}

	@keyframes sanjiao {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.fullPage {
		transition: all 0.5s linear;
	}

	.fix {
		position: fixed;
		top: 40vh;
		right: 0;
		background-color: #1a191a;
		border-radius: 3rem;

		.list {
			position: absolute;
			left: -6rem;
			top: 3rem;
			background-color: #1a191a;
			border-radius: 0.4rem;
			display: none;
			animation: sanjiao 0.3s linear;

			.xsj {
				width: 10px;
				height: 3rem;
				position: absolute;
				right: -8px;
				top: 24px;

				.xxsj {
					width: 8px;
					height: 10px;
				}
			}

			.lChild {
				color: #d6b098;
				margin: 1rem 1.2rem;
				font-size: 0.8rem;
			}
		}

		.child {
			padding: 1rem;
			width: 1.6rem;
			height: 1.6rem;
			border-radius: 3rem;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.child:nth-child(2):hover {
			.list {
				display: block;
			}
		}

		.child:first-child {
			background-color: #1e70e0;
		}
	}

	.expert {
		.center {
			margin-top: 1rem;
		}

		.titleTxt {
			color: #217bff;
			font-size: 1.1rem;
			font-weight: bold;
		}

		.titleMore {
			color: #217bff;
			font-size: 0.9rem;
			cursor: pointer;
		}

		.rightSec {
			width: 38rem;
			padding: 0 1rem;
			height: 16rem;
			align-self: flex-start;

			.talent {
				flex-wrap: wrap;

				.child {
					width: 17rem;
					height: 8.4rem;
					margin: 0.6rem 0;
					background-color: #f2f7ff;
					align-self: flex-start;

					.rightSecs {
						min-width: 7.4rem;
						height: 8.4rem;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.leftSecs {
						height: 7.4rem;
						padding: 0 1rem;
						flex-grow: 1;

						.name {
							color: #1a1a1a;
							font-size: 1.2rem;
							font-weight: bold;
						}

						.info {
							color: #767676;
							font-size: 0.9rem;
							margin: 0.4rem 0;

							span {
								margin: 0 0.2rem;
							}
						}

						.job {
							font-size: 0.8rem;
							margin-bottom: 0.3125rem;

							.hTxt {
								color: #767676;
							}

							.cTxt {
								color: #1a1a1a;
							}
						}
					}
				}
			}
		}

		.leftSec {
			width: 37rem;
			align-self: flex-start;
			padding-right: 1rem;

			.personList {
				margin: 0.6rem 0;
				height: 14.2rem;
				flex-wrap: wrap;
				animation: sanjiao .6s linear;

				.personChild {
					margin: 0.2rem 0.4rem;
					width: 8.4rem;
					position: relative;
					border-radius: 0.375rem;
					overflow: hidden;

					.personBot {
						width: 100%;
						background-image: linear-gradient(to right bottom, #78befd, #4885d7);
						color: #fff;
						padding: 0.6rem 0;
						text-align: center;

						.name {
							font-size: 1.1rem;
						}

						.skill {
							margin: 0 0.5rem;
							height: 2rem;
							font-size: 0.7rem;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
						}
					}

					.personImg {
						width: 8.4rem;
						height: 8rem;
						border-radius: 1rem;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.tabs {
				overflow: hidden;

				::-webkit-scrollbar {
					height: 0.125rem;
				}

				.nList {
					width: 100%;
					transition: all 0.5s ease;
					overflow-x: auto;
					padding: 0.6rem 0;
				}

				.tabChild {
					white-space: nowrap;
					transition: all 0.6s ease-in-out;
					color: #217bff;
					padding: 0.6rem 1rem;
					background-color: #fff;
					box-shadow: 0 2px 5px #ecf2fd;
					margin: 0 0.6rem;
					border-radius: 1.4rem;
					position: relative;
				}

				.little {
					position: absolute;
					bottom: 6px;
					left: 45%;
					width: 12px;
					height: 6px;

					img {
						animation: sanjiao 0.4s ease-in-out;
						width: 100%;
						height: 100%;
					}
				}

				.active {
					color: #fff;
					background-color: #217bff;
				}

				.tabChild:first-child {
					margin: 0 1.2rem 0 0;
				}
			}
		}
	}

	.serviceSystem {
		.list {
			padding: 2rem 1rem;
			background-color: #f2f7ff;
			border-radius: 8px;
			overflow: hidden;

			.nLists {
				width: 100%;
				display: flex;
				// flex-wrap:wrap;
				// transition: all 1s linear;
				// animation: marquee 20s linear infinite;

			}

			@keyframes marquee {
				0% {
					transform: translateX(0);
				}

				100% {
					transform: translateX(-100%);
				}
			}

			.child {
				margin: 0 20px;
				// width: 300px;
				min-width:180px;
				background-color: #fff;
				border-radius: 10px;
				padding: 1rem;

				.txt {
					color: #1a1a1a;
					font-size: 0.9rem;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}

				.logo {
					width: 2.8rem;
					height: 2.8rem;
					margin-right: 10px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 10px;
					}
				}
			}
		}
	}

	.enterprise {
		.center {
			margin-top: 1rem;
		}

		.rightSec {
			margin-left: 0.6rem;

			.child {
				padding: 2.5rem 1.4rem;
				margin: 0 0.4rem;
				background-position: center center;
				background-size: 100% 100%;

				.num {
					color: #fff;
					text-align: center;
					font-weight: bold;
					font-size: 1.8rem;
				}

				.txt {
					color: #fff;
					font-weight: bold;
					text-align: center;
					margin: 1rem 0;
					font-size: 1.1rem;
				}

				.topImg {
					width: 1.8rem;
					height: 1.8rem;
					margin: auto;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.child:first-child {
				background-image: url("../../assets/img/zczxbg.png");
			}

			.child:nth-child(2) {
				background-image: url("../../assets/img/fwxqbg.png");
			}

			.child:last-child {
				background-image: url("../../assets/img/wtjybg.png");
				margin: 0 0 0 0.4rem;
			}

		}

		.leftSec {
			background-color: #fafafc;
			border: 1px dashed #dfdfe2;
			flex-grow: 1;
			padding: 1.5rem 1.2rem;

			.btn {
				margin-top: 1.2rem;
				color: #fff;
				font-weight: bold;
				text-align: center;
				width: 7.5rem;
				background-color: #217bff;
				border-radius: 1.6rem;
				padding: 0.6rem 0;
			}

			.steps {
				.arrowhead {
					width: 2rem;
					height: 2rem;
					margin: 0 1rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.childStep {
					padding: 1.2rem;
					background-color: #fff;
					box-shadow: 0 5px 5px #ebf2fc;

					.txt {
						font-size: 0.9rem;
						margin-top: 0.5rem;
						color: #323232;
						text-align: center;
					}

					.img {
						width: 2rem;
						margin: auto;
						height: 2rem;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}

	.service {
		.list {
			margin-top: 0.5rem;
			flex-wrap: wrap;

			.child:hover {
				box-shadow: 10px 10px 10px #eee, -10px -10px 10px #eee;
			}

			.child {
				transition: all 0.2s linear;
				width: 18%;
				margin: 0.5rem 0;
				margin-right: 2.5%;
				background-color: #fff;
				box-shadow: 0 2px 10px #edf2f7;

				.botTxt {
					padding: 0 0.6rem;
					margin-top: 1rem;
					height: 4rem;
					color: #1a1a1a;
				}

				.topImg {
					width: 100%;
					height: 9rem;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
			}

			.child:nth-child(5n) {
				margin-right: 0;
			}
		}
	}

	::v-deep .el-calendar-table .el-calendar-day {
		height: 35px;
		text-align: center;
	}

	::v-deep .el-calendar__body {
		padding: 0;
	}

	::v-deep .el-calendar-table thead th {
		padding: 6px 0;
	}

	::v-deep .gutter {
		background-color: #fafafc !important;
	}

	::v-deep .el-table {
		tr {
			background-color: #fafafc !important;
		}

		.el-table__cell {
			padding: 0.1rem 0;
			border: none;
		}
	}

	::v-deep .el-table::before {
		height: 0;
	}

	::v-deep .has-gutter th {
		color: #293543;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		border: none;
		background-color: #fafafc !important;
	}

	::v-deep .el-table .cell {
		font-size: 12px;
	}

	::v-deep .el-table td:first-child .cell {
		color: #217bff;
	}

	.activity {
		.rightSec {
			margin-top: 1rem;

			.child {
				margin: 0 1rem;
				padding: 0 1rem;
				height: 18rem;
				width: 10.5rem;
				border: 1px solid #dfe7f0;

				.img {
					width: 10.5rem;
					height: 7rem;
					margin-top: 0.4rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.botC {
					color: #7f97bb;
					font-size: 0.8rem;
				}

				.titleC {
					color: #1a1a1a;
					margin: 0.4rem 0;
					height: 2.6rem;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				.topC {
					margin-top: 1rem;
					width: fit-content;
					border-radius: 10px;
					color: #fff;
					font-weight: bold;
					padding: 6px 8px;
					background-image: linear-gradient(to right, #78befd, #4885d7);
				}
			}
		}

		.leftSec {
			margin-top: 1rem;
			min-width: 32rem;
			height: 22rem;

			.lftTitle {
				color: #1a1a1a;
				font-size: 1.1rem;

				span {
					margin-left: 0.2rem;
					color: #5099f7;
					font-size: 0.9rem;
				}
			}
		}
	}

	.policyData {
		.center {
			height: 28.5rem;
			margin-top: 1rem;

			.rightSec {
				height: 28.5rem;
				padding: 0 1rem;
				width: 56rem;
				background-color: #f2f7ff;

				.tabList {
					margin: 0.8rem 0;
					background-color: #fff;
					border-radius: 2rem;
					width: fit-content;

					.tabChild {
						font-size: 1rem;
						padding: 0.8rem 1.3rem;
						border-left: 1px solid #E1EAF9;
					}

					.tabChild:first-child {
						border-left: none;
					}

					.active {
						color: #217BFF;
					}
				}

				.list {
					height: 19.5rem;
					overflow-y: auto;
				}

				.topData {
					padding: 0.5rem;
					background-color: #fafafc;
					margin: 0.5rem;
					border: 1px dashed #dfdfe2;
					transition: all .2s linear;

					.topTitle {
						color: #1a1a1a;
						font-size: 1rem;
					}

					.table {
						margin-top: 0.5rem;
						background-color: #fafafc;
					}
				}

				.topData:hover {
					box-shadow: 5px 5px 10px #eee, -5px -5px 10px #eee;
				}

				.rightTitle {
					padding: 1rem 0 0;
					color: #1a1a1a;
					font-size: 1.1rem;
				}

				.list {
					flex-wrap: wrap;

					.child {
						width: 24rem;
						margin: 0.6rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
						background-color: #fff;
						padding: 0.2rem 0.6rem;
						line-height: 2rem;
						font-size: 1rem;
						color: #1a1a1a;
						cursor: pointer;
						box-shadow: 0 5px 5px #e1ecf8;
					}
				}
			}

			.leftSec {
				padding: 0 1rem;
				background-image: url("../../assets/img/indexzczy.png");
				background-position: center center;
				background-size: 100% 100%;
				height: 28.5rem;
				max-width: 19rem;
				min-width: 19rem;

				.list {
					padding: 1rem 0;

					.child {
						margin: 1.1rem 0;
						cursor: pointer;

						.little {
							min-width: 4px;
							height: 4px;
							border-radius: 4px;
							background-color: #fff;
							margin-right: 10px;
						}

						.txts {
							font-size: 0.88rem;
							// font-size: 14px;
							color: #fff;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							overflow: hidden;
						}
					}

					.child:first-child {
						margin: 0 0 1rem;
					}

					li {
						color: #fff;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}
				}

				.leftTitle {
					padding: 1rem 0;
					border-bottom: 2px solid #fff;

					.more {
						color: #fff;
						font-size: 0.8rem;
					}

					.txt {
						font-size: 1.4rem;
						letter-spacing: 0.1rem;
						font-weight: bold;
						color: #fff;
					}
				}
			}
		}
	}

	::v-deep .el-carousel__item--card.is-active {
		transform: translateX(160px) scale(1) !important;
		width: 72%;
	}

	.dataScreen {
		.screenData {
			margin-top: 1rem;
			height: 30rem;

			img {
				width: 100%;
				height: 26rem;
				object-fit: fill;
			}
		}
	}

	.policy {
		.center {
			background-image: url("../../assets/img/zczxindex.png");
			background-position: center center;
			background-size: 100% 100%;
			padding: 2rem;
			margin-top: 1rem;

			.centerList {
				padding: 1rem;
				background-color: #fff;
				margin: 1rem 0;
				flex-wrap: wrap;

				.child {
					width: 47%;
					padding: 0.4rem 0.8rem;
					margin: 0.4rem 0;
					transition: all .2s linear;
					border-radius: 1rem;
				}

				.child:hover {
					background-color: #eee;
				}

				.littleD {
					margin-right: 0.8rem;
					min-width: 6px;
					height: 6px;
					background-color: #217bff;
					border-radius: 50%;
					align-self: flex-start;
					margin-top: 8px;
				}

				.txt {
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
				}
			}

			.centerTitle {
				background-color: #fff;
				border-radius: 2rem;
				width: fit-content;

				.childTitle {
					width: fit-content;
					cursor: pointer;
					border-left: 1px solid #eaf0fa;
					border-right: 1px solid #eaf0fa;
					color: #000;
					padding: 0.6rem 1.4rem;
					font-weight: bold;
				}

				.col {
					color: #217bff;
				}

				.childTitle:first-child {
					border-left: none;
				}

				.childTitle:last-child {
					border-right: none;
				}
			}
		}
	}

	.section {
		width: 75rem;
		margin: 2rem auto;
	}

	.tjsb {
		.tjsbCenter {
			margin-top: 1rem;
			flex-grow: 1;
			height: 23rem;

			.tjsbList {
				height: 23rem;
				margin-left: 1rem;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				.child {
					margin-top: 1rem;
					cursor: pointer;
					transition: all .2s linear;

					.titles {
						color: #1a1a1a;
						margin-top: 0.2rem;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.date {
						color: #767676;
						margin-bottom: 0.2rem;
					}

					.txts {
						flex-grow: 1;
						padding: 0 0.8rem;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: flex-start;
						height: 5rem;
					}

					.leftImg {
						width: 160px;
						height: 5rem;

						img {
							object-fit: cover;
							width: 160px;
							height: 100%;
						}
					}
				}

				.child:first-child {
					margin: 0;
				}

				.child:hover {
					box-shadow: 5px 5px 10px #eee, -5px -5px 10px #eee;
				}
			}

			.swiperSec {
				min-width: 37rem;
				position: relative;

				.botTag {
					position: absolute;
					z-index: 99;
					width: 100%;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.5);
					padding: 1rem 0;

					.littles {
						margin-right: 1rem;

						.little {
							width: 8px;
							height: 8px;
							cursor: pointer;
							background-color: #fff;
							border-radius: 50%;
							margin: 0 6px;
						}

						.active {
							background-color: #217bff;
						}
					}

					.txts {
						margin-left: 1rem;
						color: #fff;
						font-weight: bold;
						font-size: 1.2rem;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.title {
		padding-bottom: 0.6rem;
		border-bottom: 1px solid #edf3fc;

		.rightImg {
			width: 1.2rem;
			height: 1.2rem;
			margin-left: 0.5rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.rightTitle {
			cursor: pointer;
		}

		.rightTxt {
			color: #217bff;
			font-size: 0.9rem;
			margin-right: 0.1rem;
		}

		.leftTxt {
			font-size: 1.3rem;
			font-weight: bold;
			color: #000;
			margin-left: 0.1rem;
		}

		.leftImg {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.5rem;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.numList {
		margin: -6rem auto 2rem;
		padding: 0.8rem 0;
		background-color: #deedf9;
		box-shadow: 0 10px 20px #deedf9, 0 -10px 20px #deedf9;

		.data {
			color: #03103e;
			font-size: 1.5rem;
			font-weight: bold;
		}

		.btn {
			margin-top: 0.6rem;

			.goBtn {
				background-image: linear-gradient(#006efc, #004ce1);
				color: #fff;
			}
		}

		.childList {
			width: 80rem;
			margin: auto;
		}

		.numChild {
			.rightSec {
				.num {
					color: #03103e;
					font-size: 1.6rem;
					font-weight: bold;
				}

				.txt {
					margin-top: 0.4rem;
					font-size: 0.9rem;
					color: #747b84;
					text-align: center;
				}
			}

			.imgs {
				width: 7.6rem;
				height: 7.6rem;
				margin-right: 0.6rem;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.img {
				width: 3.8rem;
				height: 3.8rem;
				margin-right: 0.6rem;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.tags {
		background-image: linear-gradient(to right, #1e70e0, #5698f3, #1e70e0);
		box-shadow: 0 0.3rem 1.3rem #5698f3;

		.list {
			width: 75rem;
			padding: 0.8rem 0;
			margin: 0 auto;

			.child {
				color: #fff;
				cursor: pointer;
			}
		}
	}

	.search {
		background-image: url("../../assets/img/indexBanner.png");
		background-color: #dfdfe2;
		background-position: center center;
		background-size: 100% 100%;
		height: 45rem;
		overflow: hidden;

		.logo {
			margin: 16rem 0 0 10rem;
			width: 36rem;
			height: 3rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.topList {
			margin: 1rem 0 0 10rem;

			.child {
				margin: 0 1.2rem;
				color: #1a1a1a;
				padding: 0.2rem 1rem;
				border-radius: 1rem;
				cursor: pointer;
			}

			.active {
				background-color: #1e70e0;
				color: #fff;
			}

			.child:first-child {
				margin: 0 1.2rem 0 0.6rem;
			}

			.child:hover {
				background-color: #1e70e0;
				color: #fff;
				transition: all 0.2s ease-in-out;
			}
		}

		.topSec {
			margin: 1rem 0 0 10rem;
			width: 36rem;
			background-color: #fff;
			padding: 0.4rem 0.6rem;
			border-radius: 1.8rem;

			input {
				background-color: #fff !important;
			}

			.sel {
				margin: 0 0.6rem;
				cursor: pointer;
			}

			.btn {
				margin-left: 0.6rem;
				width: 2rem;
				text-align: center;
				padding: 0.6rem 1.4rem;
				border-radius: 1.4rem;
				color: #fff;
				cursor: pointer;
				background-image: linear-gradient(to right, #73b9fa, #4b89d9);
			}

			.ipt {
				margin: 0 1rem;
				flex-grow: 1;
				line-height: 1.6rem;
				color: #aaaaaa;
				font-size: 1rem;

				input {
					line-height: 2rem;
					border: none;
					outline: none;
				}
			}

			.searchImg {
				width: 1.4rem;
				height: 1.4rem;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>